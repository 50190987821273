import styled from 'styled-components'
import { Wrapper } from 'components/common'
import { COLOR, GTR, BORDER } from 'styles/tokens'

const ErrorSummary = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S};
  color: ${COLOR.STATE.ERROR};
  margin-bottom: ${GTR.L};
`
export default ErrorSummary
