import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR, GTR } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'
import { H } from 'components/common'

import Icon from 'components/Icon'

const StyledH = styled(H)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${screenMax.s`    
    flex-direction: column;
  `}
`

const StyledIcon = styled(Icon.Check)`
  color: ${COLOR.ACCENT.PRIMARY};
  margin-right: ${GTR.M};
  width: 36px;
  min-width: 36px;

  ${screenMax.s`    
    && {
      margin: 0 auto ${GTR.S};
    }
  `}
`

const SuccessHeading = ({ children, color, size }) => {
  return (
    <StyledH size={size} color={color}>
      <StyledIcon width={36} />
      {children}
    </StyledH>
  )
}

SuccessHeading.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
}

SuccessHeading.defaultProps = {
  color: COLOR.GREY.DARK,
  size: 'XL',
}

export default SuccessHeading
