import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, useFormikContext } from 'formik'
import * as yup from 'yup'
import { Link } from 'gatsby'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import axios from 'axios'

import PATHS from 'routes/paths'

import phoneNumberValidation from 'lib/validation/phone-number'
import scrollToDataId from 'lib/window/scroll-to-data-id'

import ErrorSummary from 'components/form/ErrorSummary'
import SuccessHeading from 'components/SuccessHeading'
import { Button, Wrapper, Grid, H } from 'components/common'
import Input from 'components/form/Input'
import Checkbox from 'components/form/Checkbox'
import Box from 'components/Box'
import { COLOR } from 'styles/tokens'

const CALLBACK_REQUEST_ZAP_URL =
  'https://hooks.zapier.com/hooks/catch/7432126/oig9c2t/'

const schema = yup.object().shape({
  firstName: yup.string().required('This field is required.'),
  lastName: yup.string().required('This field is required.'),
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('This field is required.'),
  phoneNumber: yup
    .string()
    .test(...phoneNumberValidation)
    .required('This field is required.'),
  terms: yup.boolean().oneOf([true], 'This field is required.'),
})

const InnerForm = ({ error }) => {
  const { errors, touched, isSubmitting } = useFormikContext()
  const visibleErrors = pick(errors, Object.keys(touched))
  const showErrorSummary = !isEmpty(visibleErrors)

  return (
    <Form>
      <Box>
        <Grid>
          <Grid.Item>
            <Input name="firstName" label="First name" required />
          </Grid.Item>
          <Grid.Item>
            <Input name="lastName" label="Last name" required />
          </Grid.Item>
          <Grid.Item>
            <Input name="email" type="email" label="Email" required />
          </Grid.Item>
          <Grid.Item>
            <Input name="phoneNumber" label="Phone number" required />
          </Grid.Item>
          <Grid.Item>
            <Input name="company" label="Company" optional />
          </Grid.Item>
        </Grid>
      </Box>
      <Grid>
        <Grid.Item spanFromM={6} startColumnFromM={4}>
          <Checkbox
            name="terms"
            label={
              <>
                I agree to the <Link to={PATHS.TERMS}>Terms of use</Link> and{' '}
                <Link to={PATHS.PRIVACY}>Privacy Policy</Link>
              </>
            }
          />
          <Wrapper centered margin={['XL', 0, 0]}>
            {error && <ErrorSummary>{error}</ErrorSummary>}
            {showErrorSummary && (
              <ErrorSummary>Please complete all required fields.</ErrorSummary>
            )}
            <Button.Primary type="submit" stretch loading={isSubmitting}>
              Submit request
            </Button.Primary>
          </Wrapper>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

InnerForm.propTypes = {
  error: PropTypes.string,
}

InnerForm.defaultProps = {
  error: '',
}

const Callback = () => {
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (values) => {
    setError(null)

    const formData = new FormData()
    map(values, (value, key) => {
      formData.append(key, value)
    })

    try {
      await axios.post(CALLBACK_REQUEST_ZAP_URL, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      setIsSuccessful(true)
      scrollToDataId('form')
    } catch (e) {
      // eslint-disable-next-line
      Sentry.captureException(e)
      setError(
        'There was an a problem submitting the form. Please try again, or send us an email at hello@pixel-pond.com.'
      )
    }
  }

  return (
    <Wrapper background={COLOR.BACKGROUND.PALE} data-scroll-id="form">
      <Grid container>
        <Grid.Item spanFromL={8} startColumnFromL={3}>
          {!isSuccessful && (
            <>
              <H size="L" centered margin={[0, 0, 'XL']}>
                Request a callback
              </H>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  company: '',
                  terms: false,
                }}
                validationSchema={schema}
                onSubmit={handleSubmit}
              >
                <InnerForm error={error} />
              </Formik>
            </>
          )}
          {isSuccessful && (
            <Box>
              <Wrapper padding={['M', 0]} centered>
                <SuccessHeading size="M">
                  Thanks for getting in touch
                </SuccessHeading>
                <p>We’ll get back to you as soon as possible.</p>
              </Wrapper>
            </Box>
          )}
        </Grid.Item>
      </Grid>
    </Wrapper>
  )
}

export default Callback
