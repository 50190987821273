import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'

import Hero from './Hero'
import Callback from './Callback'

const Register = () => (
  <Layout>
    <SEO
      title="Contact us"
      description="Want to find out more? Get in touch with the Pixel Pond team."
    />
    <Hero />
    <Callback />
  </Layout>
)

export default Register
