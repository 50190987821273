import styled from 'styled-components'
import { Wrapper } from 'components/common'
import { COLOR, GTR, BORDER } from 'styles/tokens'
import { screenMax } from 'styles/helpers/responsive'

const Box = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.L};
  border-radius: ${BORDER.RADIUS.S};
  margin-bottom: ${GTR.L};

  ${screenMax.s`
    padding: ${GTR.M};
  `}
`

export default Box
