import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import Image from 'components/Image'
import { H, Grid, Wrapper } from 'components/common'
import { COLOR, FONT } from 'styles/tokens'

const StyledWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
`

const StyledP = styled.p`
  font-size: ${FONT.SIZE.L};
`

const StyledLink = styled.a`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const Hero = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "team/cleo-smiling-towards-camera.jpg" }
      ) {
        ...fluidImage
      }
    }
  `)

  return (
    <StyledWrapper>
      <Wrapper container>
        <H size="XL" centered margin={[0, 0, 'XL']} marginFromL={[0, 0, 'XXL']}>
          We’d love to chat
        </H>
        <Grid>
          <Grid.Item spanFromM={6} spanFromL={5} startColumnFromL={2}>
            <Image fluid={image.childImageSharp.fluid} />
          </Grid.Item>
          <Grid.Item
            spanFromM={5}
            startColumnFromM={8}
            style={{ alignSelf: 'center' }}
          >
            <Wrapper margin={[0, 0, 'M']}>
              <H size="M" color={COLOR.ACCENT.PRIMARY} margin={[0, 0, 'S']}>
                Email us
              </H>
              <StyledP>
                <StyledLink href="mailto:hello@pixel-pond.com">
                  hello@pixel-pond.com
                </StyledLink>
              </StyledP>
            </Wrapper>
            <Wrapper margin={[0, 0, 'M']}>
              <H size="M" color={COLOR.ACCENT.PRIMARY} margin={[0, 0, 'S']}>
                Call us
              </H>
              <StyledP>+44 (0) 203 239 4462</StyledP>
            </Wrapper>
            <H size="M" color={COLOR.ACCENT.PRIMARY} margin={[0, 0, 'S']}>
              Find us
            </H>
            <StyledP>
              Unit 78, Containerville
              <br />
              35 Corbridge Crescent
              <br />
              London
              <br />
              E2 9DT
            </StyledP>
          </Grid.Item>
        </Grid>
      </Wrapper>
    </StyledWrapper>
  )
}

export default Hero
